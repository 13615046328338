import {
  LocationQueryValue,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouteLocation,
} from 'vue-router'
import { RoleName } from '@/api/useUsers'
import { RouteError } from '@/modules/base/config/routesConfig'

type IsAccessibleRoute = ({
  authorized,
  userRole,
}: {
  authorized?: RoleName[]
  userRole: RoleName
}) => boolean

export interface RoutingData {
  route: RouteLocationNormalized
  userRole: RoleName
}
type GetFirstSiblingRedirect = ({
  route,
  userRole,
}: RoutingData) => RouteRecordRaw | undefined

/**
 * getCurrentViewAuthorized - get the array with authorized user roles for current view
 */

export const getCurrentViewAuthorized = (
  route: RouteLocation | RouteRecordRaw
) => route.meta?.authorize

/**
 * isAccessible - confirms if a given route is accessible for any given user role
 */

export const isAccessibleRoute: IsAccessibleRoute = ({
  authorized,
  userRole,
}) => authorized?.includes(userRole) ?? false

/**
 * getFirstSiblingRedirect - find and return first suitable sibling.
 * A sibling to /settings/my-account is /settings/general-info in example
 */

export const getFirstSiblingRedirect: GetFirstSiblingRedirect = ({
  route,
  userRole,
}) => {
  if (!route?.matched || route?.matched.length === 0) {
    return undefined
  }

  return route.matched
    .find(({ children }) => {
      if (children?.length > 0) {
        return !!children.find((childRoute) =>
          isAccessibleRoute({
            authorized: getCurrentViewAuthorized(childRoute),
            userRole,
          })
        )
      }

      return false
    })
    ?.children.find((childRoute) =>
      isAccessibleRoute({
        authorized: getCurrentViewAuthorized(childRoute),
        userRole,
      })
    )
}

/**
 * Ensure that query param is of id type
 * @param query from `useRoute()`
 * @returns undefined or id as number
 * @example
 * ```ts
 * const route = useRoute()
 * const id = queryToNumber(route.query.location)
 * ```
 */
export const queryToNumber = (
  query: LocationQueryValue | LocationQueryValue[]
) => {
  const number = Number(query)
  return isNaN(number) ? undefined : number
}

/**
 * Create a route location with an error message
 * @description it should be handled globally in the `AppRouterGuard.vue`
 * @param to redirect destination
 * @param error details
 * @returns RouteLocation object
 */
export const redirectWithError = (
  to: RouteLocation,
  error: { type: RouteError }
): RouteLocation => {
  return {
    ...to,
    query: {
      errorType: error.type,
    },
  }
}
